body {
  font-family: 'Plus Jakarta Sans', sans-serif;
}
  .css-1nwvv8f-MuiContainer-root {
    width: 170% !important;
  }
body .css-l4u8b9-MuiInputBase-root-MuiInput-root:before {
  border-bottom: none;
}
.css-l4u8b9-MuiInputBase-root-MuiInput-root:hover {
  border-bottom: 2px solid #d7d7e1;
  transition: border-bottom 2s ease;
}
.css-27dm0d-MuiPaper-root-MuiAppBar-root {
  box-shadow: none !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  color: #000000 !important;
  font-weight: 600 !important;
  text-transform: unset !important;
  min-height: 63px !important;
}
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  align-items: flex-start !important;
}

.css-lzvbj1-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
  color: #000000 !important;
}
.css-19kzrtu {
  padding: 20px 0 !important;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
}
.css-2m9kme-MuiFormControl-root {
  width: 276px !important;
}
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  display: none !important;
}

.customAccordian.css-1elwnq4-MuiPaper-root-MuiAccordion-root::before{
  width:calc(100% - 30px);
  left: 15px;
}

.pagination .Mui-selected {
  color:#FFFFFF;
  background-color: #4A2CF5 !important;
}


.wrap_login_page {
  display: flex;

}

.wrap_login_page .css-a6z0us-MuiInputBase-root-MuiOutlinedInput-root  {
  border-radius: 6px !important;
}

.wrap_login_first_part {
  width: 100%;
  /* margin: auto; */
}

.wrap_forgot_password {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrap-rember {
  display: flex;
  align-items: center;
}

.wrap_alpha_serach {
  width: 100%;
  background: url("/public/Image/login_blue_bg.png") no-repeat #4A2CF5;
  max-width: 55%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background-size: cover;
}

.alpha_search {
  display: flex;
  margin: 120px auto auto;
}

.list_design_image {
  width: 100%;
   /* position: absolute;
  right: 0; */
}

.group_img {
  position: absolute;
  top:348px;
  left:59px;
}

.loginButton{
  background: #4A2CF5 !important;
  border-radius: 6px !important;
  padding-block: 22px !important;
}

.login_div{
  margin: auto;
  display: flex;
  max-width: 411px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1200px) {
  .wrap_alpha_serach {
      display: none;
  }
  .wrap_login_page{
      height: 100vh;
  }

  .login_div{
      max-width: 700px;
      width: 100%;
      padding: 0 15px;
  }
}

@media screen and (max-width: 1506px) {
.forgot_div{
  max-width: 700px;
  width: 100%;
  padding: 80px 15px;
}
}

@media screen and (min-width: 1506px) {
  .show_mobile{
      display: none;
  }
}
